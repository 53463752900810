import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { Subscription } from 'rxjs';
import { ContentService } from '../services/content/content.service';

export const contentResolver: ResolveFn<any> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const contentService = inject(ContentService);
      if (Array.isArray(route.data.loadContent) && route.data.loadContent.length) {
        if (route.data.async) {
          return contentService.getContent(route.data.loadContent)
        } else {
          const sub: Subscription = contentService.getContent(route.data.loadContent).subscribe(val => sub.unsubscribe());
        }
      }
      return undefined;
    };
